/**
 *
 * Failed Payments Page
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Map } from 'immutable';
import moment from 'moment-timezone';
import { phoneSize } from 'global-styles.js';
import SVG from 'react-inlinesvg';
import MailSvg from 'images/Support/mail.svg';
import SupportIcon from 'images/FailedPayments/support.svg';
import usePaymentsModal from './PaymentProcessingModal/index.js';
import _ from 'lodash';

import { makeSelectUser } from 'containers/UserInformation/selectors';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CURRENCY } from 'utils/environment.js';
import {
  fetchFailedAppointments,
  retryFailedPayments,
  updateDefaultCard,
} from './actions.js';
import {
  makeSelectFailedAppointments,
  makeSelectProcessedPayments,
} from './selectors.js';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import saga from './saga.js';
import reducer from './reducer.js';
import StripeButtonWrapper from 'components/StripeButtonWrapper';
import PanelWrapper from 'components/PanelWrapper/index.js';
import Panel from 'components/Panel/index.js';
import { Link } from 'react-router-dom';

const ActionButton = styled.button`
  display: flex;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background: ${(props) =>
    props.theme === 'green'
      ? 'linear-gradient(270deg, #25A37C -0.23%, #39D7C3 100%)'
      : '#fff'};
  color: ${(props) => (props.theme === 'green' ? '#F5FAFA' : '#2954c3')};
  padding: 8px 48px;
  white-space: nowrap;
  font-size: 16px;
  font-family: 'Barlow';
  font-weight: 600;
  line-height: 20px;
  box-shadow: ${(props) =>
    props.theme !== 'green' && '0px 1px 9px 0px #dde5ef'};
  width: 274px;
  a {
    color: inherit;
  }
  a:hover {
    text-decoration: none;
  }
`;

const PanelContent = styled.div`
  padding: 48px;

  h1 {
    color: #2e60ca;
    font-family: 'Barlow';
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 62px;
    letter-spacing: 0.14px;
    margin: 0 0 48px 0;
  }

  h2 {
    color: #193269;
    font-family: 'Barlow';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.14px;
    margin-bottom: 32px;
  }

  h3 {
    color: #193269;
    font-family: 'Barlow';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
`;

const SummaryDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 580px;
`;

const JustifyDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  color: #193269;
  font-family: 'Barlow';
  font-size: 18px;
  font-style: normal;
  line-height: 26px; /* 144.444% */
  font-weight: 600;
  div:first-child {
    font-weight: 700;
  }
`;

const Border = styled.div`
  border-top: 1px solid #dfe8e8;
  width: 100%;
  margin: 16px 0px;
`;

const Body = styled.div`
  color: var(--text-body, #000);
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
`;

const ButtonWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  gap: 20px;

  @media (max-width: ${phoneSize}) {
    gap: 8px;
    flex-direction: column;
    align-items: center;

    div {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }
`;

const SupportPanelWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SupportItem = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  span {
    color: var(--text-body, #000);
    font-family: 'Barlow';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;

    a {
      color: #2e60ca;
    }
  }
`;

function formattedCurrency(amount) {
  const formattedAmount = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: CURRENCY,
  }).format(amount);

  return `${formattedAmount} ${CURRENCY}`;
}

const FailedPaymentsPage = (props) => {
  const {
    fetchFailedAppointments,
    retryFailedPayments,
    user,
    updateDefaultCard,
    location,
    intl,
  } = props;
  const [PaymentProcessingModal, openModal] = usePaymentsModal();
  const [paymentState, setPaymentState] = useState('idle');

  useEffect(() => {
    fetchFailedAppointments();
  }, [fetchFailedAppointments]);

  const handleToken = (token) => {
    setPaymentState('processing');
    openModal();

    updateDefaultCard(token, () => {
      const ids = failedAppointments.map((appt) => appt.id);
      retryFailedPayments(ids, (processedPayments) => {
        if (processedPayments.length === failedAppointments.length) {
          if (_.every(processedPayments, { success: true })) {
            setPaymentState('success');
          } else if (_.every(processedPayments, { success: false })) {
            setPaymentState('failure');
          } else {
            setPaymentState('partialSuccess');
            fetchFailedAppointments();
          }
        } else {
          setPaymentState('partialSuccess');
          fetchFailedAppointments();
        }
      });
    });
  };

  const handleTotalAmount = () =>
    formattedCurrency(_.sum(failedAppointments.map((appt) => appt.amount)));

  const handleTaxAmount = () =>
    formattedCurrency(_.sum(failedAppointments.map((appt) => appt.tax)));

  const handleSubtotal = () =>
    formattedCurrency(
      _.sum(failedAppointments.map((appt) => appt.amount - appt.tax)),
    );

  const parseAppointmentType = (appt) => {
    switch (appt.provider_type) {
      case 'generic':
        return appt.clinical_program_category_name;
      case 'couples':
        return <FormattedMessage defaultMessage="Couples" />;
      case 'individual':
        return <FormattedMessage defaultMessage="Individual" />;
      default:
        break;
    }
  };

  const failedAppointments = props.failedAppointments.toJS();

  const bookingPathInfo = location?.state?.bookingPathInfo;

  return (
    <PanelWrapper>
      <PaymentProcessingModal
        handleTotalAmount={handleTotalAmount}
        paymentState={paymentState}
        bookingPathInfo={bookingPathInfo}
      />
      <Panel>
        <PanelContent>
          <h1>
            <FormattedMessage defaultMessage="Update payment details and pay outstanding balance" />
          </h1>
          <h2>
            <FormattedMessage defaultMessage="Billing Summary" />
          </h2>

          <SummaryDiv>
            {failedAppointments.map((appt) => (
              <>
                <JustifyDiv>
                  <div>
                    <FormattedMessage defaultMessage="Appointment date:" />
                  </div>
                  <div>{formattedCurrency(appt.amount - appt.tax)}</div>
                </JustifyDiv>
                <Body>
                  {moment
                    .tz(appt.start_date, user.get('timezone'))
                    .format('dddd, MMMM DD, YYYY [at] hh:mmA [(]z[)]')}
                </Body>
                <JustifyDiv>
                  <div>
                    <FormattedMessage defaultMessage="Appointment type:" />
                  </div>
                </JustifyDiv>
                <Body>{parseAppointmentType(appt)}</Body>
                <JustifyDiv>
                  <div>
                    <FormattedMessage defaultMessage="Care provider" />
                  </div>
                </JustifyDiv>
                <Body>{appt.provider_name}</Body>
                <Border />
              </>
            ))}
            <JustifyDiv>
              <div>
                <FormattedMessage defaultMessage="Subtotal" />
              </div>
              <div>{handleSubtotal()}</div>
            </JustifyDiv>
            <JustifyDiv>
              <div>
                <FormattedMessage defaultMessage="Tax" />
              </div>
              <div>{handleTaxAmount()}</div>
            </JustifyDiv>
            <Border />
            <JustifyDiv>
              <div>
                <FormattedMessage defaultMessage="Total" />
              </div>
              <div>{handleTotalAmount()}</div>
            </JustifyDiv>
          </SummaryDiv>
          <ButtonWrapper>
            <ActionButton>
              <Link to="/">
                <FormattedMessage defaultMessage="Back to Dashboard" />
              </Link>
            </ActionButton>
            <StripeButtonWrapper
              onToken={handleToken}
              email={user.get('email')}
              locale={user.get('locale')}
              panelLabel={intl.formatMessage(
                { defaultMessage: 'Pay {cost}' },
                { cost: handleTotalAmount() },
              )}
              description={''}
            >
              <ActionButton theme={'green'}>
                <FormattedMessage defaultMessage="Pay Balance" />
              </ActionButton>
            </StripeButtonWrapper>
          </ButtonWrapper>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelContent>
          <h2 style={{ marginBottom: '10px' }}>
            <FormattedMessage defaultMessage="Need help?" />
          </h2>
          <h3>
            <FormattedMessage defaultMessage="Contact Us" />
          </h3>
          <SupportPanelWrapper>
            <SupportItem>
              <SVG src={MailSvg} alt="mail icon" />
              <span>
                <a href="mailto:support@inkblottherapy.com">
                  support@inkblottherapy.com
                </a>
              </span>
            </SupportItem>
            <SupportItem>
              <SVG src={SupportIcon} alt="support icon" />
              <span>
                <FormattedMessage defaultMessage="Live chat: Every day from 8:00am to 11:00pm (ET)" />
              </span>
            </SupportItem>
            <SupportItem>
              <SVG src={SupportIcon} alt="support icon" />
              <span>
                <FormattedMessage
                  defaultMessage="Visit <helpCentre>the Help Centre</helpCentre> to learn more about updating payment
                    details and paying outstanding balances."
                  values={{
                    helpCentre: () => (
                      <a href="https://google.ca">
                        <FormattedMessage defaultMessage="the Help Centre" />
                      </a>
                    ),
                  }}
                />
              </span>
            </SupportItem>
          </SupportPanelWrapper>
        </PanelContent>
      </Panel>
    </PanelWrapper>
  );
};

FailedPaymentsPage.propTypes = {
  user: PropTypes.instanceOf(Map),
  locale: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    updateDefaultCard: (token, callback) =>
      dispatch(updateDefaultCard(token, callback)),
    fetchFailedAppointments: () => dispatch(fetchFailedAppointments()),
    retryFailedPayments: (ids, callback) =>
      dispatch(retryFailedPayments(ids, callback)),
  };
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  failedAppointments: makeSelectFailedAppointments(),
  processedPayments: makeSelectProcessedPayments(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'failedPaymentsPage', reducer });
const withSaga = injectSaga({ key: 'failedPaymentsPage', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  injectIntl,
)(FailedPaymentsPage);
